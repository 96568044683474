import React from 'react';

import Content from 'src/components/Content';

type Props = {
  value?: string | null;
};

export default function RichText({ value }: Props) {
  return <Content content={value} />;
}
