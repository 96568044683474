import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { SITE_URL } from 'src/config/siteInfo';
import { SiteOptionFragment } from 'src/generated';

export function ensureProtocol(url: string, protocol = 'https') {
  if (url.startsWith('http')) return url;
  return `${protocol}://${url.replace(/^\/\//, '')}`;
}

enum DateRangeDisplay {
  SameMonth,
  SameYear,
  Other,
}

export function getDateDisplay(
  start: Date | null | undefined,
  end: Date | null | undefined,
) {
  if (start && end && start.getFullYear() === end.getFullYear()) {
    if (start.getMonth() === end.getMonth()) return DateRangeDisplay.SameMonth;
    return DateRangeDisplay.SameYear;
  }
  return DateRangeDisplay.Other;
}

export function getFormattedDateRange(
  start: Date | null | undefined,
  end: Date | null | undefined,
) {
  const display = getDateDisplay(start, end);

  if (!start) return;

  if (display === DateRangeDisplay.SameMonth || !end) {
    return format(start, 'MMM yyyy');
  }

  if (display === DateRangeDisplay.SameYear) {
    return `${format(start, 'MMM')}/${format(end, 'MMM yyyy')}`;
  }

  return `${format(start, 'MMM yyyy')} – ${format(end, 'MMM yyyy')}`;
}

export function useLatestRef<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

export function sortIgnoreArticles(a: string, b: string) {
  const re = /^(the)\s(.*)/i;
  const cleanedA = a.replace(re, '$2, $1');
  const cleanedB = b.replace(re, '$2, $1');
  if (cleanedA > cleanedB) return 1;
  if (cleanedA < cleanedB) return -1;
  return 0;
}

export function getSiteUrlForPath(path: string): string;
export function getSiteUrlForPath(path: null | undefined): null | undefined;
export function getSiteUrlForPath(
  path: string | null | undefined,
): string | null | undefined;
export function getSiteUrlForPath(
  path: string | null | undefined,
): string | null | undefined {
  if (!path) return path;
  return `${SITE_URL}${path}`;
}

const MEDIA_PREFIX = 'https://media.walkandalie.com';

export function getMediaUrlForPath(path: string): string;
export function getMediaUrlForPath(path: null | undefined): null | undefined;
export function getMediaUrlForPath(
  path: string | null | undefined,
): string | null | undefined;
export function getMediaUrlForPath(
  path: string | null | undefined,
): string | null | undefined {
  if (!path) return path;
  return `${MEDIA_PREFIX}/${path}`;
}

export function getSiteOptionsByKey(options: SiteOptionFragment[]) {
  return options.reduce<{ [key: string]: string | undefined }>(
    (acc, option) => {
      acc[option.key] = option.value;
      return acc;
    },
    {},
  );
}
