/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */
import React, { HTMLAttributes, ImgHTMLAttributes, MouseEvent } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import {
  HorsemanimagesRendition,
  SizedImageFieldsFragment,
} from 'src/generated';
import horsemanImageSizes, {
  getIsMatchingRendition,
} from 'src/horsemanImageSizes';
import { captureMessage } from '@sentry/nextjs';
import { getMediaUrlForPath } from 'src/utils';

type SizedImageRendition = Pick<
  HorsemanimagesRendition,
  'file' | 'crop' | 'width' | 'height' | 'targetWidth' | 'targetHeight'
>;

export type SizedImageData = SizedImageFieldsFragment;

export type ImageSize = Readonly<{
  readonly name: string;
  readonly crop: boolean;
  readonly size: Readonly<[width: number, height: number]>;
  readonly extraWidths: Readonly<number[]>;
}>;

export type OnClick = (
  evt: MouseEvent<HTMLImageElement>,
  imageId: string,
) => void;

type Props = Omit<HTMLAttributes<HTMLImageElement>, 'title' | 'onClick'> & {
  image: SizedImageData;
  size: ImageSize | Readonly<ImageSize[]>;
  defaultSize?: ImageSize | Readonly<ImageSize[]>;
  title?: string | null;
  className?: string;
  sizes?: string | string[];
  isResponsive?: boolean;
  isNoSrc?: boolean;
  onClick?: OnClick;
};

export default function SizedImage({
  image,
  size,
  defaultSize = [horsemanImageSizes['BlogPost.body'].image.blog_image],
  sizes,
  title,
  className,
  isResponsive = true,
  isNoSrc = false,
  onClick,
  ...imgProps
}: Props) {
  let srcs: SizedImageRendition[] = [];
  let originalSizeParams: ImageSize | null = null;
  let original: SizedImageData | SizedImageRendition | null = null;
  const defaultSizeArr: Readonly<ImageSize[]> = Array.isArray(defaultSize)
    ? defaultSize
    : [defaultSize];

  const sizesArr: Readonly<ImageSize[]> = Array.isArray(size)
    ? [...(size as ImageSize[]), ...defaultSizeArr]
    : [size as ImageSize, ...defaultSizeArr];

  if (image && !isNoSrc) {
    original = null;

    for (let i = 0; i < sizesArr.length; i += 1) {
      const sizeParams = sizesArr[i];
      const matchingRendition = image.renditions.find((rendition) =>
        getIsMatchingRendition(rendition, sizeParams),
      );
      if (matchingRendition) {
        originalSizeParams = sizeParams;
        original = matchingRendition;
        break;
      }
    }

    if (!original) {
      original = image;
      const errorMsg = `Missing image rendition ${sizesArr
        .map((s) => s.name)
        .join(', ')} for ${image.file}`;
      if (process.env.NODE_ENV === 'production') {
        captureMessage(errorMsg, {
          extra: {
            sizes: sizesArr,
            image: omit(image, ['meta', 'renditions']),
          },
          fingerprint: ['missing_image_rendition'],
        });
      } else {
        console.warn(errorMsg);
      }
    }

    if (originalSizeParams) {
      const originalAspect =
        originalSizeParams.size[0] / originalSizeParams.size[1];
      const widths: number[] = [];
      srcs = image.renditions
        .filter((rendition) => {
          const aspect = rendition.targetWidth / rendition.targetHeight;
          if (
            rendition.crop === originalSizeParams?.crop &&
            Math.round(aspect * 100) === Math.round(originalAspect * 100) &&
            widths.indexOf(rendition.width) === -1
          ) {
            widths.push(rendition.width);
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          if (a.width > b.width) return 1;
          if (a.width < b.width) return -1;
          return 0;
        });
    }
  }
  const width = original?.width || image.width;
  const height = original?.height || image.height;
  let sizesStr = `${original?.width || image.width}px`;
  if (sizes) {
    sizesStr = Array.isArray(sizes) ? sizes.join(', ') : sizes;
  }
  const extraProps: Partial<ImgHTMLAttributes<HTMLImageElement>> = {};
  if (typeof title !== 'undefined') {
    if (title) extraProps.title = title;
  } else {
    extraProps.title = image.title || undefined;
  }
  if (!isNoSrc) {
    extraProps.src = getMediaUrlForPath(original?.file || image.file);
    if (isResponsive) {
      extraProps.srcSet = srcs
        .map(
          (details) => `${getMediaUrlForPath(details.file)} ${details.width}w`,
        )
        .join(', ');
      extraProps.sizes = sizesStr;
    }
  }
  return (
    <img
      alt={image.title || undefined}
      className={classNames(
        `size-${originalSizeParams?.name || 'full'}`,
        `orientation-${width < height ? 'portrait' : 'landscape'}`,
        className,
        {
          'can-click': !!onClick,
        },
      )}
      width={width}
      height={height}
      onClick={(e) => onClick && onClick(e, image.id)}
      {...extraProps}
      {...imgProps}
    />
  );
}
