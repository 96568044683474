import React from 'react';
import classNames from 'classnames';

import SizedImage, {
  ImageSize,
  OnClick,
  SizedImageData,
} from 'src/components/SizedImage';

import styles from './styles.module.scss';

type Props = {
  columns: number;
  images: (SizedImageData | null)[];
  isResponsiveImages?: boolean;
  onImageClick?: OnClick;
  size: ImageSize;
};

export default function Gallery({
  columns,
  images,
  isResponsiveImages = true,
  onImageClick,
  size,
}: Props) {
  return (
    <div
      className={classNames(
        'gallery',
        `gallery-columns-${columns}`,
        `gallery-size-${size.name}`,
        styles.gallery,
      )}
    >
      <div className={styles['gallery-inner']}>
        {images.map((image) =>
          image ? (
            <figure
              key={image.id}
              className={classNames('gallery-item', styles['gallery-item'])}
            >
              <div
                className={classNames(
                  'gallery-icon',
                  image.width > image.height ? 'landscape' : 'portrait',
                  styles['gallery-icon'],
                )}
              >
                <SizedImage
                  image={image}
                  size={size}
                  sizes={[
                    `(min-width: 1714px) ${1065 / columns}px`,
                    `(min-width: 1342px) calc((70vw - 130px) / ${columns})`,
                    `(min-width: 941px) ${810 / columns}px`,
                    `(min-width: 752px) calc((100vw - 130px) / ${columns})`,
                    `${(1 / columns) * 100}vw`,
                  ]}
                  isResponsive={isResponsiveImages}
                  onClick={onImageClick}
                />
              </div>
            </figure>
          ) : null,
        )}
      </div>
    </div>
  );
}
