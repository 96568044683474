import React from 'react';

type Props = {
  value?: string | null;
};

export default function HTML({ value }: Props) {
  return (
    <div
      dangerouslySetInnerHTML={value ? { __html: value } : undefined} // eslint-disable-line react/no-danger
    />
  );
}
