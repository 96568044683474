import { HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'content'> & {
  content?: string | null;
};

export default function Content({ content, ...other }: Props) {
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleLinkClick = useCallback(
    (e: globalThis.MouseEvent) => {
      if (
        e &&
        e.target &&
        (e.target as HTMLAnchorElement).hostname === window.location.hostname &&
        (e.target as HTMLAnchorElement).target !== '_blank'
      ) {
        e.preventDefault();
        router.push((e.target as HTMLAnchorElement).pathname).catch(() => {});
      }
    },
    [router],
  );

  useEffect(() => {
    const { current: container } = containerRef;
    const links = container?.getElementsByTagName('a');
    Array.from(links || []).forEach((link) =>
      link.addEventListener('click', handleLinkClick),
    );

    return () => {
      const rmLinks = container?.getElementsByTagName('a');
      Array.from(rmLinks || []).forEach((link) =>
        link.removeEventListener('click', handleLinkClick),
      );
    };
  }, [containerRef, handleLinkClick]);

  return (
    <div
      {...other}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={content ? { __html: content } : undefined}
      ref={containerRef}
    />
  );
}
