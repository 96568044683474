/* eslint-disable */

const horsemanImageSizes = {
  "BlogPlace.featuredImage": {
    "default": {
      "taxonomy-overview-card": {
        "name": "taxonomy-overview-card",
        "verboseName": "Taxonomy Overview Card",
        "crop": true,
        "size": [
          280,
          365
        ],
        "extraWidths": [
          170,
          400,
          800
        ]
      }
    }
  },
  "BlogSpecificplace.featuredImage": {
    "default": {
      "taxonomy-overview-card": {
        "name": "taxonomy-overview-card",
        "verboseName": "Taxonomy Overview Card",
        "crop": true,
        "size": [
          280,
          365
        ],
        "extraWidths": [
          170,
          400,
          800
        ]
      }
    }
  },
  "BlogTrip.featuredImage": {
    "default": {
      "taxonomy-overview-card": {
        "name": "taxonomy-overview-card",
        "verboseName": "Taxonomy Overview Card",
        "crop": true,
        "size": [
          280,
          365
        ],
        "extraWidths": [
          170,
          400,
          800
        ]
      },
      "square_thumb": {
        "name": "square_thumb",
        "verboseName": "Square thumbnail",
        "crop": true,
        "size": [
          93,
          93
        ],
        "extraWidths": [
          186
        ]
      },
      "post_trips_sidebar": {
        "name": "post_trips_sidebar",
        "verboseName": "Post/Trips Sidebar",
        "crop": true,
        "size": [
          432,
          160
        ],
        "extraWidths": [
          216,
          864
        ]
      }
    }
  },
  "BlogPost.body": {
    "image": {
      "blog_image": {
        "name": "blog_image",
        "crop": false,
        "size": [
          900,
          900
        ],
        "extraWidths": [
          300,
          600,
          1200,
          2400
        ]
      }
    },
    "gallery": {
      "square": {
        "name": "square",
        "crop": true,
        "size": [
          600,
          600
        ],
        "extraWidths": [
          150,
          300,
          900,
          1200
        ]
      },
      "2x3": {
        "name": "2x3",
        "verboseName": "2x3 (tall)",
        "crop": true,
        "size": [
          600,
          900
        ],
        "extraWidths": [
          150,
          300,
          900
        ]
      },
      "3x2": {
        "name": "3x2",
        "verboseName": "3x2 (wide)",
        "crop": true,
        "size": [
          900,
          600
        ],
        "extraWidths": [
          225,
          450,
          1350
        ]
      }
    }
  },
  "BlogPost.featuredImage": {
    "default": {
      "3x2": {
        "name": "3x2",
        "verboseName": "3x2 (wide)",
        "crop": true,
        "size": [
          900,
          600
        ],
        "extraWidths": [
          300,
          600,
          1200,
          1800,
          2400
        ]
      },
      "square_thumb": {
        "name": "square_thumb",
        "verboseName": "Square thumbnail",
        "crop": true,
        "size": [
          93,
          93
        ],
        "extraWidths": [
          186
        ]
      }
    }
  },
  "PagesPage.body": {
    "image": {
      "blog_image": {
        "name": "blog_image",
        "crop": false,
        "size": [
          900,
          900
        ],
        "extraWidths": [
          300,
          600,
          1200,
          2400
        ]
      }
    },
    "gallery": {
      "square": {
        "name": "square",
        "crop": true,
        "size": [
          600,
          600
        ],
        "extraWidths": [
          150,
          300,
          900,
          1200
        ]
      },
      "2x3": {
        "name": "2x3",
        "verboseName": "2x3 (tall)",
        "crop": true,
        "size": [
          600,
          900
        ],
        "extraWidths": [
          150,
          300,
          900
        ]
      },
      "3x2": {
        "name": "3x2",
        "verboseName": "3x2 (wide)",
        "crop": true,
        "size": [
          900,
          600
        ],
        "extraWidths": [
          225,
          450,
          1350
        ]
      }
    }
  },
  "PagesPage.featuredImage": {
    "default": {
      "3x2": {
        "name": "3x2",
        "verboseName": "3x2 (wide)",
        "crop": true,
        "size": [
          900,
          600
        ],
        "extraWidths": [
          300,
          600,
          1200,
          1800,
          2400
        ]
      },
      "blog_image": {
        "name": "blog_image",
        "crop": false,
        "size": [
          900,
          900
        ],
        "extraWidths": [
          300,
          600,
          1200,
          2400
        ]
      }
    }
  }
} as const;

export default horsemanImageSizes;

export function getIsMatchingRendition(
  rendition: { crop: boolean; targetWidth: number; targetHeight: number },
  size: Readonly<{
    crop: boolean;
    size: Readonly<[width: number, height: number]>;
  }>,
) {
  return (
    rendition.crop === size.crop &&
    rendition.targetWidth === size.size[0] &&
    rendition.targetHeight === size.size[1]
  );
}
