import React from 'react';
import classNames from 'classnames';

import SizedImage, { OnClick, SizedImageData } from 'src/components/SizedImage';
import horsemanImageSizes from 'src/horsemanImageSizes';

import styles from './styles.module.scss';

type Props = {
  image: SizedImageData;
  isPaddingAdded?: boolean;
  isResponsive?: boolean;
  onClick?: OnClick;
};

export default function Image({
  image,
  isPaddingAdded = true,
  isResponsive = true,
  onClick,
}: Props) {
  return (
    <div
      className={classNames(
        'image-wrap-width',
        'aligncenter',
        `size-blog-image${image.width < image.height ? '-tall' : ''}`,
        `orientation-${image.width > image.height ? 'landscape' : 'portrait'}`,
        styles['image-wrap-width'],
      )}
    >
      <div
        className={classNames('image-wrap', styles['image-wrap'])}
        style={
          isPaddingAdded
            ? { paddingBottom: `${(image.height / image.width) * 100}%` }
            : {}
        }
      >
        <SizedImage
          image={image}
          size={horsemanImageSizes['BlogPost.body'].image.blog_image}
          sizes={[
            '(min-width: 1714px) 1070px',
            '(min-width: 1342px) calc(70vw - 130px)',
            '(min-width: 941px) 810px',
            '(min-width: 752px) calc(100vw - 130px)',
            '100vw',
          ]}
          isResponsive={isResponsive}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
