import { HTMLAttributes } from 'react';
import { keyBy } from 'lodash';

import { StructuredFieldBlockFragment } from 'src/generated';
import horsemanImageSizes from 'src/horsemanImageSizes';
import type { OnClick } from 'src/components/SizedImage';
import { AddBlockTyping } from './blockTypes';
import RichText from './RichText';
import Image from './Image';
import Gallery from './Gallery';
import HTML from './HTML';
import Embed from './Embed';

const galleryImageSizesByName = keyBy(
  horsemanImageSizes['BlogPost.body'].gallery,
  'name',
);

export type OnImageClick = (
  evt: Parameters<OnClick>[0],
  imageId: Parameters<OnClick>[1],
  blockIndex: number,
) => void;

type Props = HTMLAttributes<HTMLDivElement> & {
  value: StructuredFieldBlockFragment[] | undefined | null;
  isTextOnly?: boolean;
  isAddPadding?: boolean;
  isResponsiveImages?: boolean;
  onImageClick?: OnImageClick;
};

export default function StructuredField({
  value,
  isTextOnly = false,
  isAddPadding = true,
  isResponsiveImages = true,
  onImageClick,
  ...props
}: Props) {
  const blocks = (
    isTextOnly ? value?.filter((b) => b.type === 'richtext') : value
  ) as AddBlockTyping<StructuredFieldBlockFragment>[] | null | undefined;
  return (
    <div {...props}>
      {blocks?.map((block, idx) => {
        if (block.type === 'richtext') {
          return <RichText key={block.index} value={block.block.value} />;
        }
        if (block.type === 'image') {
          const image = block.images?.[0];
          if (image) {
            return (
              // eslint-disable-next-line jsx-a11y/alt-text
              <Image
                key={block.index}
                image={image}
                isPaddingAdded={isAddPadding}
                isResponsive={isResponsiveImages}
                onClick={(evt, imageId) => onImageClick?.(evt, imageId, idx)}
              />
            );
          }
        }

        if (block.type === 'gallery' && block.images) {
          return (
            <Gallery
              key={block.index}
              images={block.images}
              columns={block.block.columns}
              size={galleryImageSizesByName[block.block.size]}
              isResponsiveImages={isResponsiveImages}
              onImageClick={(evt, imageId) => onImageClick?.(evt, imageId, idx)}
            />
          );
        }

        if (block.type === 'html') {
          return <HTML key={block.index} value={block.block.value} />;
        }

        if (block.type === 'oembed' && block.block.data) {
          return <Embed key={block.index} data={block.block.data} />;
        }

        return null;
      })}
    </div>
  );
}
