import React from 'react';
import classnames from 'classnames';
import { OembedData } from 'src/components/StructuredField/blockTypes';

import styles from './styles.module.scss';

type Props = {
  data?: OembedData | null;
};

export default function Embed({ data }: Props) {
  if (data && data.html) {
    return (
      <div
        className={classnames(
          { 'has-dimensions': data.width && data.height },
          styles['embed-container'],
        )}
        style={
          data.width && data.height
            ? { paddingBottom: `${(data.height / data.width) * 100}%` }
            : undefined
        }
        dangerouslySetInnerHTML={{ __html: data.html }} // eslint-disable-line react/no-danger
      />
    );
  }
  return null;
}
